@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

/*   color variables */
$clr-primary: #d50000;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;

/*   border radius */
$radius: 0.2rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.btn {
  padding: 1rem;
  font-family: inherit;
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem;
  border: 2px solid $clr-primary;
  background: transparent;
  color: $clr-primary;
  border-radius: $radius;
  transition: background 200ms ease-in, color 200ms ease-in;
}

.wrapper {
  display: grid;
  padding-top:100px;
  grid-template-columns: repeat(auto-fit, minmax(40rem, 60rem));
  justify-content: center;
}

.card {
  overflow: hidden;
  padding: 20px;
  height: 150%;

  border-radius: $radius;
  display: flex;
  flex-direction: column;
  justify-content: centre;
  cursor: pointer;
  transition: transform 200ms ease-in;

  &__image {
    height: 12rem;
    width: 100%;
    object-fit: cover;
  }

  &__title {
    padding: 1rem;
  }

  &__description {
    padding: 0 1rem;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:hover &__btn {
    background: $clr-primary;
    color: white;
  }
}
