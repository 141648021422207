.banner_Homepage {
  position: relative;
  height: 600px;
  width: 100%;
  /* border: 3px solid hsl(85, 68%, 40%); */
}
.banner_Homepage img {
  height: 100%;
  width: 100%;
}
.mid_Homepage {
  position: relative;
  height: 750px;
  width: 100%;
  /* border: 3px solid blue; */
}

.home_about_image {
  position: relative;
  height: 525px;
  width: 716px;
  top: 105px;
  left: 87px;
  /* border: 3px solid blue; */
  /* border-radius: 20px; */
}
.home_about_image img {
  height: 100%;
  width: 100%;
}

.bottom_Homepage {
  position: relative;
  height: 850px;
  width: 100%;
  /* border: 3px solid blue; */
}

.inter_text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.25px;

  color: #0f172a;
}
.homepage_about_image {
  position: absolute;
  width: 616px;
  height: 475px;
  left: 87px;
  top: 134px;

  border-radius: 20px;
}

.button_homepage_learn_more {
  width: 204px;
  height: 50px;
  position: relative;
  bottom:100px;

  background: #6893fb;
  border-radius: 20px;
}

.button_homepage_events {
  position: relative;
  width: 204px;
  height: 50px;
  left: 894px;
  top: 250px;
  background: #6893fb;
  border-radius: 20px;
}

.what_is_compclub_homepage {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  letter-spacing: -0.5px;
  color: #0f172a;
}

.carousel_div_bottom {
  position: relative;
  height: 400px;
  width: 1000px;
  top: 70px;
  left: 500px;
  /* border : 3px solid red; */
}

.carousel_div_bottom Alicecarousel {
  width: 10%;
  height: 10%;
}

.title_bottom_homepage {
  position: relative;
  top: 30px;
  left: 800px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 46px;
  line-height: 48px;
  /* or 100% */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  color: #0F172A;
}

.middle-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin: 5%;

}


.middle-text {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

}

@media (max-width: 1450px) {
  .middle-section {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 20px;
    margin: 10%;   
    flex-direction: column;

  }
  
  .middle-text {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    align-items: center;
    /* flex-direction: row; */
  }

  .middle-image {
    width: 50%;
  }
}
/* .logo {
  width: 120px;
  height: 30px;
  position: absolute;
  top: 30px;
  left: 10px;
} */
